<template>
  <div>
    <item-list-component :item-type="'myItemHold'"></item-list-component>
  </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";

export default {
  name: "CollectWriteDefaultLayout",
  mixins: [],
  components: {
    ItemListComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>